import { Injectable } from "@angular/core";
import { INTRODUCERS_TYPES } from "../utils/variables.data";

const wordingDictionary = new Map<string, Map<string, string>>([
  [
    "ESTATE_AGENT",
    new Map<string, string>([
      ["division", "branch"],
      ["divisions", "branches"],
      ["Division", "Branch"],
      ["Divisions", "Branches"],
      ["Reference", "Reference"],
    ]),
  ],
  [
    "HOUSE_BUILDER",
    new Map<string, string>([
      ["division", "site"],
      ["divisions", "sites"],
      ["Division", "Site"],
      ["Divisions", "Sites"],
      ["Reference", "Plot Number"],
    ]),
  ],
  [
    "OTHER",
    new Map<string, string>([
      ["division", "subdivision"],
      ["divisions", "subdivisions"],
      ["Division", "Subdivision"],
      ["Divisions", "Subdivisions"],
      ["Region", "Division"],
      ["region", "division"],
      ["Regions", "Divisions"],
      ["regions", "divisions"],
    ]),
  ],
]);

@Injectable({ providedIn: "root" })
export class WordingService {
  public introducerDictKey: string;

  public transform(
    wording: string,
    introducerType: INTRODUCERS_TYPES | string
  ): string {
    const key = introducerType || this.introducerDictKey;

    if (!key) {
      return wording;
    }

    const introducerDict = wordingDictionary.get(key);

    if (!introducerDict) {
      return wording;
    }

    const transformed = introducerDict.get(wording);

    return transformed || wording;
  }
}
