export abstract class Environment {
  production: boolean;
  apiUrl: string;
  apiVersion: string;
  rawApiUrl: string;
  config: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
  };

  requestFactFindEnabled: boolean;
  clientWebUrl: string;
  swaggerApiLinks: {
    introducerApi: string;
  };

  protected setup(environment: any) {
    for (const key in environment) {
      this[key] = environment[key];
    }
  }
}
